
.container {
    padding: 2rem 0rem;
    user-select: none;
  }
  
  .staff-heading{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    border-bottom: 2px solid ;
  }

  .staff-links{
    margin-left: auto;
    display: flex;
    gap:20px;
    margin-bottom: 10px;
  }
  h4 {
    margin: 2rem 0rem 1rem;
  }
  
  .table-image {
    vertical-align: middle;
  }
  td, th {
    vertical-align: middle;
  }
  thead{
    color: #007eff;
  }

  thead, tbody{
    text-align: center;
  }

  td> img{
    width: 200px;
  }
 
  #table-head >th{
    font-size: 1.3rem;
  }
 
  #staff-name{
    font-weight: bolder;
    font-size: 1.2rem;
  }
  /* Pagination */
  nav{
    margin:0 auto;
    padding-top:60px;
  }
  .pagination.pagination-circle .page-item.active .page-link {
      border-radius: 50%;
  }
  .pagination .page-item .page-link:hover {
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
      border-radius: .125rem;
      background-color: #eee;
  }
  .pagination.pg-blue .page-item.active .page-link:hover {
      background-color: #BD5410;
  }
  .pagination .page-item.active .page-link {
      -webkit-transition: all .2s linear;
      transition: all .2s linear;
      border-radius: .125rem;
      background-color: #BD5410;
      color: #fff;
  }
  .pagination .page-item .page-link {
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
      outline: 0;
      border: 0;
      background-color: transparent;
      font-size: .9rem;
      color: #999;
      font-weight:bold;
  }
  .pagination.pagination-circle .page-item .page-link:hover {
      border-radius: 50%;
  }
  .pagination.pagination-circle .page-item .page-link {
      margin-left: 2px;
      margin-right: 2px;
      border-radius: 50%;
  }
  .pagination .page-item.active .page-link{
      box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  }
  .page-link{
    border:none!important;
  }

  .select {
    margin-bottom: 1em;
    padding: .25em;
    border: 0;
    background: none;
    border-bottom: 2px solid currentcolor; 
    font-weight: bold;
    letter-spacing: .15em;
    border-radius: 0;
    &:focus, &:active {
      outline: 0;
      border-bottom-color: #007eff;
    }
  }