.card-content{
	height:300px;
  }
  
  #card{
	  height: auto;
	  margin-bottom:20px;
  }
  #card > img {
	display: block;
	margin: 0 auto;
	max-width: 100%;
	max-height: 200px;
	object-fit: cover;
	
  }
  #card-text {
	font-size: 85%;
	line-height: 1.2;
	text-align: justify;
  }
  
  #intro-para > h4{
	line-height: 2;
	font-size: 14px;
  }
  
  /*village palika */
  
  #card-group{
	margin-top:10px;
  }
  #card-title{
	margin-bottom: 0;
  }
  #map-icon > svg{
	display: block;
	max-width: 32px;
  }
 
  .homepage-heading{
    font-size: 1.6rem;
    font-weight: 800;
    color: #007eff;
  }

  .simana-data{
    font-weight: bolder;
  }

  #card-body{
    padding: 1rem;
  }
  
  #destination-icon{
    margin-bottom: 5px;
  }
$small: 768px;
$big: 900px;
#destination {
  
  @media screen and (max-width: $small) {
    display:none;
  }
  @media screen and (min-width: $big) {
    display: block;
  }
}

#modal-bold{
  font-weight: 900;
}