.dropdown-toggle.nav-link:after,
.dropdown-toggle.btn:after {
	right: 10px;
	top: 15px;
	position: absolute !important;
}

#map-view{
	z-index: 1000;
	display: flex;
	justify-content: flex-end;
	position: absolute;
	font-size: 1.3rem;
	width: 100%;
	padding: 20px;
}
#map-view > a{
	background-color: #fff;
	border-radius: 2px;
	color: black;
	border: 2px solid #ccc;
	height: auto;
	display: block;
	max-width: 32px;
	padding: 5px;
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
