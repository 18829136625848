* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	height: 100vh;
}

body {
	font-family: "Courier New", Courier, monospace;
	color: white;
}

.main {
	display: flex;
	height: 100vh;
}

.container {
	flex-grow: 1;
	padding: 2rem;
	background-size: cover;
	background-position: center;
	/* color: #fff; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}

.title {
	font-size: 3em;
}

.info {
	font-size: 1.1em;
	letter-spacing: 1px;
	line-height: 1.5;
	margin: 1.5em;
	color: white;
}

.btn {
	margin: 0 auto;
	border: none;
	outline: none;
	padding: 0.75em 1em;
	font-size: 1em;
	letter-spacing: 1px;
	font-weight: bold;
	text-transform: uppercase;
	border-radius: 3px;
	color: #fff;
}

h1,
p {
	color: black;
}

.sidebar-item,
.sidebar-item0,
.sidebar-item1,
.sidebar-item2,
.sidebar-item3,
.sidebar-item4,
.sidebar-item5,
.sidebar-item6,
.sidebar-item7,
.sidebar-item8,
.sidebar-item9,
.sidebar-item10,
.sidebar-item11 {
	padding: 1em;
	font-size: 1em;
	display: block;
	transition: background-color 0.3s;
	cursor: pointer;
}

/* .sidebar-item:hover{
    background-color:  rgba(255,255,255, .1);
  }

  .sidebar-item0:hover{
    background-color:  rgba(255,255,255, .1);
  }
   */
.sidebar-title {
	display: flex;
	font-size: 1.2em;
	justify-content: space-between;
}

.sidebar-title span i {
	display: inline-block;
	width: 1.5em;
}
.sidebar-title .toggle-button {
	content: pointer;
	transition: transform 0.3s;
}

.sidebar-content {
	padding-top: 0.25em;
	height: 0;
	overflow: hidden;
}

.sidebar-item.open .sidebar-title .toggle-button,
.sidebar-item0.open .sidebar-title .toggle-button,
.sidebar-item1.open .sidebar-title .toggle-button,
.sidebar-item2.open .sidebar-title .toggle-button,
.sidebar-item3.open .sidebar-title .toggle-button,
.sidebar-item4.open .sidebar-title .toggle-button,
.sidebar-item5.open .sidebar-title .toggle-button,
.sidebar-item6.open .sidebar-title .toggle-button,
.sidebar-item7.open .sidebar-title .toggle-button,
.sidebar-item8.open .sidebar-title .toggle-button,
.sidebar-item9.open .sidebar-title .toggle-button,
.sidebar-item10.open .sidebar-title .toggle-button,
.sidebar-item11.open .sidebar-title .toggle-button {
	transform: rotate(180deg);
}

.sidebar-item.open .sidebar-content,
.sidebar-item0.open .sidebar-content,
.sidebar-item1.open .sidebar-content,
.sidebar-item2.open .sidebar-content,
.sidebar-item3.open .sidebar-content,
.sidebar-item4.open .sidebar-content,
.sidebar-item5.open .sidebar-content,
.sidebar-item6.open .sidebar-content,
.sidebar-item7.open .sidebar-content,
.sidebar-item8.open .sidebar-content,
.sidebar-item9.open .sidebar-content,
.sidebar-item10.open .sidebar-content,
.sidebar-item11.open .sidebar-content {
	height: auto;
}

.big-map {
	height: 100%;
	width: 100%;
	position: fixed;
	overflow: hidden;
}

.map-content {
	height: 100%;
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: 50px auto;
	grid-template-areas:
		"h h h h h h h h h h h h"
		"s s s m m m m m m m m m";
}

.sidebar {
	left: -100%;
}

.sidebar.active {
	grid-area: s;
	width: 250px;
	flex-shrink: 0;
	background-color: #375cc2;
	color: white;
	height: 100%;
	overflow-y: scroll;
	left: 0;
	z-index: 10;
	text-transform: uppercase;
}

.big-map {
	grid-area: m;
}

.toggle-bars {
	z-index: 12;
}

.header-map {
	grid-area: h;
	display: flex;
	justify-content: space-between;
	background: linear-gradient(#1e3a8a, #312e81);
	z-index: 12;
}

.bars {
	font-size: 2rem;
	margin-top: 10px;
	color: white;
	margin-left: 20px;
}

.search-bar {
	padding: 5px;
}




.sidebar-title > svg {
	color: #1e3a8a;
}

#map-headerLeft{
	display: flex;
	justify-content: space-between;
	gap:40px;
}

#map-headerText{
	padding: 2px;
}

#map-headerText > h3{
	font-size: 1.2rem;
}
#map-headerText > p{
	font-size: 1rem;
}


@media screen and (max-width: 768px) {
	#map-headerLeft{
		gap: 10px;
		align-items: center;
	}
	.search {
		padding: 10px;
		border-right: none;
		outline: none;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		width: 100px;
	}
	.search-button {
		padding: 10px;
		text-align: center;
		color: #000;
		background-color: white;
		border-left: none;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}
}

@media screen and (min-width: 768px) {
	.search {
		padding: 10px;
		border-right: none;
		outline: none;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		width: 250px;
	}
	.search-button {
		padding: 10px;
		text-align: center;
		color: #000;
		background-color: white;
		border-left: none;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}
}